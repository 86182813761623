import * as msal from "@azure/msal-browser";

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const textracerPolicies: IMsalPolicies = {
    names: {
        signUpSignIn: process.env.VUE_APP_signUpSignInB2CScope !== undefined ? process.env.VUE_APP_signUpSignInB2CScope : "",
        forgotPassword: process.env.VUE_APP_forgotPasswordB2CScope !== undefined ? process.env.VUE_APP_forgotPasswordB2CScope : "",
        editProfile: process.env.VUE_APP_editProfileB2CScope !== undefined ? process.env.VUE_APP_editProfileB2CScope : "",
    },
    authorities: {
        signUpSignIn: {
            authority: `https://${process.env.VUE_APP_B2C_authority}.b2clogin.com/${process.env.VUE_APP_B2C_authority}.onmicrosoft.com/${process.env.VUE_APP_signUpSignInB2CScope}`,
            scopes: []
        },
        forgotPassword: {
            authority: `https://${process.env.VUE_APP_B2C_authority}.b2clogin.com/${process.env.VUE_APP_B2C_authority}.onmicrosoft.com/${process.env.VUE_APP_forgotPasswordB2CScope}`,
            scopes: ["openid profile"]
        },
        editProfile: {
            authority: `https://${process.env.VUE_APP_B2C_authority}.b2clogin.com/${process.env.VUE_APP_B2C_authority}.onmicrosoft.com/${process.env.VUE_APP_editProfileB2CScope}`,
            scopes: ["openid profile"]
        }
    },
    authorityDomain: `${process.env.VUE_APP_B2C_authority}.b2clogin.com`
};

export const AuthenticationConfig: msal.Configuration = {
    auth: {
        clientId: process.env.VUE_APP_clientId !== undefined ? process.env.VUE_APP_clientId : "",
        authority: textracerPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [textracerPolicies.authorityDomain],
        redirectUri: process.env.VUE_APP_rootUrl
    },
    cache: {
        cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
        storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
    }, system: {
        loggerOptions: {
            loggerCallback: (level: msal.LogLevel, message: string, containsPii: boolean): void => {
                switch (level) {
                    case msal.LogLevel.Error:
                        console.error(message);
                        return;
                }
            },
            piiLoggingEnabled: false
        }
    }
};

export const ImageStoreConfiguration = {
    baseUrl: process.env.VUE_APP_imageStoreBaseUrl
};

export const ApplicationConfig: IApplicationConfig = {
    endpoints: {
        dashboardApi: process.env.VUE_APP_Api_Endpoint !== undefined ? process.env.VUE_APP_Api_Endpoint : ""
    },
    scopes: {
        dashboardApi: [process.env.VUE_APP_Api_scope !== undefined ? process.env.VUE_APP_Api_scope : ""]
    }
};

interface IMsalPolicies {
    names: {
        signUpSignIn: string | undefined,
        forgotPassword: string | undefined,
        editProfile: string | undefined
    },
    authorities: {
        signUpSignIn: msal.RedirectRequest,
        forgotPassword: msal.RedirectRequest,
        editProfile: msal.RedirectRequest
    },
    authorityDomain: string
}

interface IApplicationConfig {
    endpoints: {
        [key: string]: string
    };
    scopes: {
        [key: string]: string[]
    };
}
